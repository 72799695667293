import {
  QuerySalesRapportArgs,
  SalesRapportInput,
  Ticket,
} from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { useLazyQuery } from "@vue/apollo-composable";
import { activeActivity } from "@/plugins/i18n";
import { reactive, ref } from "vue";
import moment from "moment";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { formatNumber } from "@/graphql/utils/utils";
import { PAYMENT_RECEIPT_FIELDS } from "@/graphql/payment-receipt/payment-receipt";
import {
  TICKET_FIELDS,
  TicketStatusEnum,
  getDiscountValues,
} from "@/graphql/ticket/ticket";
import { printRapport } from "@/components/rapport/printer";
import { useI18n } from "vue-i18n";

type SalesRapportData = {
  salesRapport: Ticket[];
};

const defaultTotals = {
  netSalePrice: 0,
  purchasePrice: 0,
  balance: 0,
  payed: 0,
  sold: 0,
};

type SaleRapportRow = Ticket & typeof defaultTotals;

const SALES_RAPPORT = gql`
  query SalesRapport($input: SalesRapportInput!) {
    salesRapport(input: $input) {
      ${TICKET_FIELDS}
      sequence {
        id
        number
        terminal {
          id
          name
        }
      }
      movements {
        price
        quantity
        product {
          id
          notStorable {
            purchasePrice
          }
        }
        stock {
          id
          purchasePrice
        }
      }
      payments {
        ${PAYMENT_RECEIPT_FIELDS}
      }
      customer {
        id
        name
      }
    }
  }
`;

const FILTER = {
  createdAt: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  "sequence.terminal.name": {
    operator: FilterOperator.AND,
    constraints: [{ value: "", matchMode: FilterMatchMode.CONTAINS }],
  },
  "sequence.number": {
    operator: FilterOperator.AND,
    constraints: [{ value: "", matchMode: FilterMatchMode.CONTAINS }],
  },
  number: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  "customer.name": {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  netSalePrice: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  purchasePrice: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  balance: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  payed: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  sold: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  status: {
    operator: FilterOperator.AND,
    constraints: [{ value: [], matchMode: FilterMatchMode.IN }],
  },
};

export const useRapportTicketIncome = () => {
  const filteredTotals = reactive({
    ...defaultTotals,
  });

  const filters = ref({ ...FILTER });
  const date = new Date();
  const { t, d, tm } = useI18n();
  const input = reactive<SalesRapportInput>({
    startAt: moment(date).add(-2, "days").toDate(),
    endAt: date,
    sequences: [],
    activityId: activeActivity.value.id,
    period: null,
  });

  const { loading, load, onResult } = useLazyQuery<
    SalesRapportData,
    QuerySalesRapportArgs
  >(SALES_RAPPORT, { input: { ...input } });

  const sales = ref<SaleRapportRow[]>([]);

  const resetFilterValues = () => {
    Object.assign(filteredTotals, { ...defaultTotals });
  };

  onResult(({ data }) => {
    if (!data.salesRapport.length) return;
    resetFilterValues();
    sales.value = data.salesRapport.map((e) => {
      const { offerAmount, offerPercentage, total, received, isOffered, sold } =
        getDiscountValues(e);
      const sign = e.parentId ? -1 : 1;
      // Put payed credit as payed
      let status = e.status === TicketStatusEnum.CREDIT_PAYED ? 0 : e.status;
      if (isOffered) status = TicketStatusEnum.OFFER;

      e.amount = sign * offerAmount;
      e.percentage = sign * offerPercentage;

      const netSalePrice = total - offerAmount;

      const purchasePrices = e.movements.reduce((sum, cur) => {
        const unitSalePrice =
          (cur?.stock
            ? cur.stock.purchasePrice
            : cur.product?.notStorable?.purchasePrice) || 0;
        return sum + unitSalePrice * cur.quantity;
      }, 0);

      return {
        ...e,
        number: e.number || 0,
        status,
        netSalePrice: sign * netSalePrice,
        purchasePrice: purchasePrices,
        balance: sign * (netSalePrice - purchasePrices),
        payed: sign * received,
        sold: sign * sold,
        createdAt: new Date(e.createdAt),
      };
    });
  });

  function refresh() {
    Object.assign(filters.value, FILTER);
  }

  function initData() {
    void load(
      SALES_RAPPORT,
      {
        input: { ...input },
      },
      { fetchPolicy: "no-cache" }
    );
  }

  function print() {
    printRapport(`<table>
      <thead>
        <tr>
          <th class="p-text-uppercase" colspan="11">
            <h2 class="p-pt-3">${t("rapport.saleWithIncome")}</h2>
          </th>
        </tr>
        ${
          !input.sequences.length
            ? `<tr>
          <th colspan="5" style="border-right: unset">
            ${t("rapport.period")}
          </th>
          <th colspan="3" class="p-no-border">
            ${t("rapport.from")}
            ${d(input.startAt, "long")}
          </th>
          <th colspan="3" style="border-left: unset">
            ${t("rapport.to")}
            ${d(input.endAt, "long")}
          </th>
        </tr>`
            : ` <tr>
          <th colspan="11">
            ${t("rapport.ofSequences", { number: input.sequences.length })}
          </th>
        </tr>`
        }
        <tr style="padding-top: 16px; padding-bottom: 16px">
          <th class="p-text-left">${t("date")}</th>
          <th>${t("rapport.ticketNumber")}</th>
          <th>${t("pos.terminal")}</th>
          <th>${t("pos.sequence")}</th>
          <th>${t("rapport.client")}</th>
          <th>${t("rapport.netSalePrice")}</th>
          <th>${t("product.purchasePrice")}</th>
          <th>${t("rapport.balance")}</th>
          <th>${t("rapport.payed")}</th>
          <th>${t("payment.sold")}</th>
          <th style="text-align: right">${t("product.type")}</th>
        </tr>
      </thead>
      <tbody>
        ${sales.value
          .map(
            (data) => `<tr>
          <td class="p-text-left">
            ${d(data.createdAt, "long")}
          </td>
          <td>${formatNumber(data.number)}</td>
          <td>${data.sequence.terminal.name}</td>
          <td>${formatNumber(data.sequence.number)}</td>
          <td>${data.customer?.name || t("customer.default")}</td>
          <td>
            ${formatNumber(data.netSalePrice)}
            ${activeActivity.value.currencySymbol}
          </td>
          <td>
            ${formatNumber(data.purchasePrice)}
            ${activeActivity.value.currencySymbol}
          </td>
          <td>
            ${formatNumber(data.balance)}
            ${activeActivity.value.currencySymbol}
          </td>
          <td>
            ${formatNumber(data.payed)}
            ${activeActivity.value.currencySymbol}
          </td>
          <td>
            ${formatNumber(data.sold)}
            ${activeActivity.value.currencySymbol}
          </td>
          <td class="p-text-right">
            ${(tm("ticket.status") as any)[data.status]}
          </td>
        </tr>`
          )
          .join("")}
      </tbody>
      <tfoot>
        <tr>
          <th colspan="5" class="p-text-left">
            ${t("rapport.total")}
          </th>
          <th>
            ${formatNumber(filteredTotals.netSalePrice)}
            ${activeActivity.value.currencySymbol}
          </th>
          <th>
            ${formatNumber(filteredTotals.purchasePrice)}
            ${activeActivity.value.currencySymbol}
          </th>
          <th>
            ${formatNumber(filteredTotals.balance)}
            ${activeActivity.value.currencySymbol}
          </th>
          <th>
            ${formatNumber(filteredTotals.payed)}
            ${activeActivity.value.currencySymbol}
          </th>
          <th>
            ${formatNumber(filteredTotals.sold)}
            ${activeActivity.value.currencySymbol}
          </th>
          <th></th>
        </tr>
      </tfoot>
    </table>`);
  }

  function onFilter(event: any) {
    resetFilterValues();
    (event.filteredValue as SaleRapportRow[]).forEach((ticket) => {
      filteredTotals.netSalePrice += ticket.netSalePrice;
      filteredTotals.purchasePrice += ticket.purchasePrice;
      filteredTotals.balance += ticket.balance;
      filteredTotals.payed += ticket.payed;
      filteredTotals.sold += ticket.sold;
    });
  }

  return {
    onFilter,
    filteredTotals,
    loading,
    sales,
    input,
    initData,
    refresh,
    filters,
    print,
    formatNumber,
  };
};
